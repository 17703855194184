.blog {
	header.page-header { margin-top:3rem; }
	article {
		padding-bottom: 3rem;
		.entry-meta { padding-bottom: 1.5rem; }
	}
}
.archive article {  padding-bottom: 3rem; }
.single {
	header.page-header { margin-top:3rem; }
	/* article { padding-top: 3rem; } */
	.entry-meta { padding-bottom: 1.5rem; }
}

.blog-sidebar {
	padding-top: 4rem;
	@include media-breakpoint-down(sm) { 
		border-top: 1px solid #e3e3e3; 
		padding-top: 1rem;	margin-top: 2rem;	
	} 	
	section.widget {
		padding: 0;
		margin: 0; 
	}
	div.label { font-weight:bold; }
}

.single img.hero { margin-bottom: 30px; } 
@media screen and (min-width:768px) { .single img.hero { float:right; max-width:350px; height:auto; margin: 0 0 30px 30px; } }

#related-posts h3 {
    border-top: 1px solid #e3e3e3;
    padding-top: 1rem;
}
