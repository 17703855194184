.home {
	h1 { color: $dark-gray !important; }	
	#services {
		text-align: center;
		div.box { min-height:300px }
		.caption-title { background: $bright-blue;   }	
		h2 {
			text-transform: uppercase;
			text-align: center;
			font-size: 1.1rem !important;
			margin: .7rem .5rem;
			font-weight: 600;
			color:$white;
			a { color: #fff; }
			a:hover { text-decoration:none; }
		}
		h3 {
			font-size: $font-size-base*1.75;
			font-weight: 500;
			position: relative;
			text-align: center;
			margin-bottom: 0.75rem;
		}
		a.btn {
			font-weight: 400;
			font-size: 0.9rem;
		}
		img {
			margin-bottom: 2rem;
			width:100%;
			height:auto; 
			padding-bottom:40px; 
		}
	} 
	#carousel {
		background: $bright-blue;
		padding: 3rem 0;
		margin: 0;
		h2 { color: #fff !important; }
		h3 {
			text-align:center;
			padding: 10px 0; 
			color: #fff;
			font-size:1.25rem !important;
		}
		.white { color: #fff; }
		img { border: 1px solid $light-blue; }
		a { 
			text-decoration:none;
			&:hover {
				img { border: 1px solid $brand-primary; }
			}
		}
		.product-carousel { 
			@include media-breakpoint-down(sm) { width:80%;margin:0 auto; }
		}		
		.slick-dots { display:none !important; }
	}	


	#testimonials {
		h2 {
			margin-bottom: 3rem;
			position: relative;
		}
		h2:after {
			content:'';
			width: 2.5rem;
			height: 2px;
			background: $brand-secondary;
			position: absolute;
			left: 48%; /* temp */
			bottom: -0.75rem;
		}
		.blockquote, .blockquote-footer {
			font-size: 1rem;
			padding: 0 1rem;
		}
		.slick-next::before, .slick-prev::before {
			color: $brand-secondary;
			font-size: 2rem;
		}
		.slick-prev {
			left: -4rem;
		}
		.slick-next {
			right: -4rem;
		}
	}
} 	


/* IMPROVE SERVICE BOXES ON LANDING, TO MATCH HOME */	
.page-template-template-landing div.box { margin-bottom:30px; }	
.page-template-template-landing .rollover-roll-text .caption-title h2 a { color: #fff !important; font-size: 1.1rem!important; display:block; width:100%;  }	
.page-template-template-landing .rollover-roll-text .caption-title h2 {	font-size: 1.1rem!important; padding-top:15px; padding-bottom:5px; font-weight:600 }
#services img { width: 100%; }	


@media screen and (max-width:767.9px) { 
	body.case-studies div.photo img { padding-bottom:1.5rem;  }
}


.page-template-template-product {
	#video-panel {
		background-color: $light-gray;
	}
	#resources {
		.btn { width: 100%; }
	}
	#need-help {
		background-color: $dark-gray;
		color: $white;
		h2 {
			color: $white;
		}
	}
}

.box {
	transition: transform 0.1s;
	position: relative;
}

/* Roll up text */
.rollover-roll-text {
	.caption {
		color: $white;
		a { color: $white; }
		position: absolute;
		width: 100%;
		height: 0;
		overflow: hidden;
		bottom: 0;
		left: 0;
		right: 0;
		text-align:center;
		background: rgba(0, 0, 0, 0.5);
		transition: height 0.3s;

		.caption-inner {
			position: absolute;
			top: 40%;
			left: 0;
			width: 80%;
			-webkit-transform: translateX(10%) translateY(-50%);
			transform: translateX(10%) translateY(-50%);
	    }
	}
	.caption-title {
		background: $brand-primary;
		position: absolute; 
		bottom: 0; 
		width: 100%;
		text-align:center; 
		a { text-decoration:none; }
    }	
	&:hover {
    	.caption { height: 100%; }
  	}
}

/* image gallery */
.product-image-gallery {
	padding: 0;
 	li {
		list-style-type: none;
		float: left;
		display: none;
		padding: 3px;

		&:first-child {
			display: block;
		}
		img {
			width: 100%;
			height: 100%;
			max-height: 100%;
		}
		.card-block {
			p {
				display: block;
				text-overflow: ellipsis;
				word-wrap: break-word;
				overflow: hidden;
				max-height: 3em;
				line-height: 1.5em;
				margin-bottom: 0;
			}
		}
	}
}
.product-image-gallery-nav {
	padding: 0;
	li {
		list-style-type: none;
		float: left;
		/*max-width: 147px;  1920 width mainly the max for this */
		display: none;
		margin: 0 0.25em;
		transition: border-bottom 0.1s;
		border-bottom: 0.25em solid $white;
		img {
			padding: 0.25em 0;
			&:hover {
				cursor: pointer;
			}
		}
	}
	li.slick-current {
		border-bottom: 0.25em solid $brand-secondary;
	}
}

/* CAREERS PAGE */
.career-post-sidebar .btn { width:100%; } 
.career-post-footer { @include media-breakpoint-down(md) { display:none; } }

/*  media queries  */
@media (max-width: 1200px) { }
@media (max-width: 992px) { }
@media (max-width: 768px) { }
@media (max-width: 544px) { }

// Webkit
@-webkit-keyframes wobble  {
  0%  { -webkit-transform:  rotate(0deg); }
  20%  { -webkit-transform:  rotate(2deg); }
  50%  { -webkit-transform:  rotate(-2deg); }
  100%  { -webkit-transform:  rotate(0deg); }
}