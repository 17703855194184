footer.site-footer {
	background: $brand-primary;
	color: #fff;
	a { color:#fff; }
	padding: 3rem 0;
	font-size: 15px; 
	
	ul.menu {
		list-style-type: none;
		padding: 0;
		li { padding-bottom:.5rem;}
		a { 
			font-size:15px;
			font-weight:bolder; 
			text-transform:uppercase;
		}
		a:hover { text-decoration: underline; }
	}	

	h3 { 
		color: #fff;
    	font-size: 18px !important;
    	text-transform: uppercase;
		padding-bottom: .5rem; 
		border-bottom: 1px solid #fff;		
	}
	.label {
		font-weight:bolder;
		font-size:18px;
	}	
	.widget_recent_entries {
		.label {
			border-bottom: 1px solid #fff; 
			text-transform:uppercase; 
			margin-bottom: 0.5rem; 
			padding-bottom: 0.5rem;
		}			
		padding-bottom: .5rem; 
		border-bottom: 1px solid #fff;
		ul {
			margin:0;padding:0;
			list-style-type: none;
			li {
				margin-bottom: .5rem;
				a { text-decoration:underline; }
			}
		}	
	}

}
@media (max-width: 992px) {}
@media (max-width: 768px) {}
@media (max-width: 544px) {}