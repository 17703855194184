header.banner {
	padding: 1rem 0 .5rem 0;
	z-index: 9999;
	background: rgb(255,255,255);
	background: linear-gradient(0deg, rgba(209,209,209,1) 0%, rgba(255,255,255,1) 65%, rgba(255,255,255,1) 100%);	
	border-bottom: 5px solid $brand-primary;

	.logo {	}
	
	#menu-header-menu {
		@include media-breakpoint-down(md) { display:none; }
		li {
			padding: 0 .25rem;
			&:after {
				content: "|";
    			color: $gray;
			}
			&:last-child:after {
				content: "";
			}			
			a {
				color: $gray;
				font-size: 0.9rem;
				font-weight: 700;
				text-transform: uppercase;
				line-height: 30px;  /* to align header nave and phone */
				padding-right: .5rem;
			}
		}
	}

	.search { 
		margin: 0 20px 0 15px;
		@include media-breakpoint-down(md) { display:none }
	}
	.mobile-search { 
		@include media-breakpoint-up(md) { display:none }
	}	
	
	.phone { 
		font-size: 1.25rem; padding-left: 15px; font-weight:700; color: $bright-blue;
		@include media-breakpoint-down(md) { text-align:right; padding-right:70px;  }
		@include media-breakpoint-down(sm) { text-align:left; padding-right:0;  }
	}
	
	.main-nav-container {
		margin-top: 12px;
	}

	.nav-primary {
		transition: margin-top 0.1s;
		li {
			transition: border-color 0.2s ease;
			padding: 0;
			& > ul {
				display: none;
			}
			a {
				padding: .5rem .75rem;
				display: block;
				color: $brand-primary; 
				text-decoration: none;
				font-weight: 700;
				/*  font-size: 0.9rem;  */
				&:hover {
					text-decoration: none;
				}
				.active {
					color: #ffad00;
				}
			}
			&.last a { padding-right: 0; }				
			&:hover {
				& > ul {
					display: block;
					position: absolute;
					background-color: $brand-primary;
				}
				.sub-menu {
					a:hover { background-color: rgba(0,0,0,.1); }
				}
			}
			&.mobile {
				display: none;
			}				
			.sub-menu {
				margin: 0;
				padding: 15px 0;
				z-index: 9999;
				a { font-size: .9rem; color:#fff;  }

				li {
					list-style-type: none;
					position: relative;
					padding: 0 20px; 
					
					.sub-menu {
						width: 100%;
						position: absolute;
						left: 100%;
						top: 0;
					}
				}
			}
		}

		/* li.menu-products > a { cursor: default; } */
		.current-menu-item { }
	}
}



/*  media queries and responsive  */
.navbar-toggle { display: none; cursor: default;}

@media (min-width: 993px) and (max-width:1199px) {  /* prevent wrapping at 1024 */
	header.banner {
		#menu-header-menu li a, .nav-primary li a {
			font-size: 13px;
		}
	}
}

@media (max-width: 992px) {
	header.banner {
		.navbar-toggle {
			display: block;
			padding: 10px;
			float: right;
			background: transparent;
			border: 0;
			.icon-bar {
				background: $brand-secondary;
			}
		}
		.navbar-toggle.offcanvas-toggle.js-offcanvas-has-events {
			position: absolute;
			right: 5px;
			top: 0;
		}
		.navbar-offcanvas.in {
			left: -250px !important;
		}
	}
}

@media (max-width: 768px) {
	.nav-primary .navbar-offcanvas.in {
		left: 300px !important;
	}
}

@media (max-width: 544px) { 	
	.navbar-toggle {
		padding: 8px 2px 8px 8px;
	}	
	header.banner {
		.navbar-toggle.offcanvas-toggle.js-offcanvas-has-events {
			top: -3px;
		}
	}
}

@media (max-width: 400px) { header.banner .phone { font-size:.9rem  } } 