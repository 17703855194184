#slider {
	.slide { height: 550px; 
		@include media-breakpoint-down(sm) { height:450px; }
	}	
	padding: 0 !important;
	margin: 0 !important;
	.btn {
		font-size: 0.9rem;
		font-weight: 400;
		text-transform: none;
		line-height: 1rem;
		padding: 0.5rem 1rem 0.75rem 1rem;
	}
	background-size: cover;
	h1, .h1 {
		color: $white;
		font-size: 3.5rem !important;
		font-weight: 400;
		padding-top: 7.5rem;
		@include media-breakpoint-up(lg) { padding-top: 10rem;}		
		@include media-breakpoint-down(md) {		
			font-size: 2.5rem !important;
		}
		@include media-breakpoint-down(sm) {		
			font-size: 2rem !important;
		}		
	}
	p {
		color: white;
		font-size: 1rem;
		font-weight: 500;
		margin-top: 2rem;
	}
	a {
		color:$white;	
	}
}


.callout { 
    background: linear-gradient(0deg,#d1d1d1,#fff 65%,#fff);
    border: 1px solid #d1d1d1;	
    color: #333;font-size: 1.25rem; font-weight:700; line-height:1.25;
    padding: 25px;
    margin: 15px 0 25px;
	img { display:block;margin: 0 auto; }
	span { font-size: 1.8rem; display:block; text-shadow: 1px 1px 2px rgba(0,0,0,.5); }
	span.large { font-size: 2rem;  }	
	a { display:block; font-weight:400; margin-top:1rem }
}
@media screen and (max-width:768px) {.callout { padding:25px 15px; text-align:center; }	}


/* TABS */
.tab-set {
	margin: 0 0 -1px 0;
	padding:0;
	list-style:none;
	> li { 
		display:inline-block;
		padding: 1rem; 
		background:$light-gray; 
		border-top: 1px solid $almost-gray;
		border-left: 1px solid $almost-gray;
		cursor: pointer;
		font-size: 1.125rem;
		text-transform: uppercase;  
		&:last-child { 
			border-right: 1px solid $almost-gray;
		}
		&.active {
			background: #fff; 
			border-top: 3px solid $brand-secondary; 
			border-bottom: 1px solid #fff; 
		} 
		/*  > a, > a:hover, > a:focus { border-radius: 0; text-transform:uppercase;  }
		&.active > a, &.active > a:hover, &.active > a:focus { border-top:0 }
		&:first-child.active a { border-left: 2px solid $almost-gray; } */
	}
}
@media screen and (max-width:767px) {
	.tab-set { display:block;  }
	.tab-set>li { float: none; margin-bottom: -1px; width:100%; border-right: 1px solid $almost-gray;  }
}

.tab-content { 
	padding: 1.2rem; 
	border: 1px solid $almost-gray; 
	> div { display: none; } 
	> div.active { /*display: block;*/ display:table; width:100%; }  /*use table to contain floating images */
}               


/* ACCORDION */
section#accordion { margin-top:0; }	
.accordion {
	margin-bottom: 1rem;
	.accordion-label {  /* button */
		width: 100%;
		background: $white;
		color: $body;
		text-align: left;
		font-weight:bold; 
		padding: 1rem;
		border: 0; 
		cursor: pointer;

		&:before {
			content: "+";
			float:left; 
			padding-right: 10px; 
			font-size: 1rem; width: 1rem;
			font-weight: bold;
			color: $brand-secondary;
			transition: transform 0.3s ease-in-out;
		}
		&.active {
			&:before { content: "-"; /* transform:rotate(-135deg); */ }
		}
	}
	ul { margin: 0; padding: 0; }
	li { list-style-type: none; }
	> ul > li { border-bottom: 1px solid $light-gray; }

	.accordion-content {
		max-height: 0; overflow: hidden; transition: all 0.35s ease-in-out;
		ul {
			padding-left:2.5rem;
		}	
		li {
			padding: 1rem;
			background: $white;
			list-style-type:disc;
		}
		div {
			padding: 0 1rem; 
		}
	}
}


/* CARDS */
.card {
	border: none;
	box-shadow: $dp-2;
	border-radius: 0;
	.card-img-top { }
}
.card-bg-primary {
	background: $brand-primary;	
}
.card-bg-secondary {
	background: $brand-secondary;
}
.card-block {
	text-align:center;
	padding: 1rem .5rem;
}


/* EKKO-LIGHTBOX MODALS */
.modal-content {
	background: transparent;
	border: none;
	border-radius: 0;
	& > .modal-header {
		border-bottom: 0;
		padding: 15px 0 0 0;

		& > .close {
			color: $white;
			opacity: 1;
			&:focus { opacity: 1;       }
			&:hover { opacity: 1;       }
		}
	}
	.ekko-lightbox-nav-overlay {
		top:43%; position:absolute; width:100%;
		& > a {
			color: $white;
			padding: 0.5em;
			opacity: 1;
			font-size:46px;
			text-decoration:none;
			&:first-child { float:left; } 		
			&:last-child { float:right; margin-right:5%; } 
		}
	}
}


/*.menu-social-media-container { */
#menu-social-media {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
	a {
		display: block;
		height: 32px;
		width: 32px;
		text-indent: -9999em;
		overflow: hidden;
	}
	li {
		background-position: top center;
		background-size: contain;
		margin: 0 0.2rem .5rem 0;
		height:32px; width:32px; 
		@include media-breakpoint-down(md) {		
			display: inline;
		}			
	}
	.linkedIn { background-image: url('/wp-content/themes/tamarin/dist/images/32-linkedin.png'); }
	.twitter { background-image: url('/wp-content/themes/tamarin/dist/images/32-twitter.png'); }
	.facebook { background-image: url('/wp-content/themes/tamarin/dist/images/32-facebook.png'); }
	.instagram { background-image: url('/wp-content/themes/tamarin/dist/images/32-instagram.png'); }
	.youtube { background-image: url('/wp-content/themes/tamarin/dist/images/32-youtube.png'); }
	.gmb { 
		background-image: url('/wp-content/themes/tamarin/dist/images/icon-googlebiz-37x32.png'); 
		height:28px;
		@include media-breakpoint-down(md) {		
			height: 32px; width:37px;
		}
	}
	@include media-breakpoint-up(md) { margin-left: 2rem; }
}


.video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
	iframe {
		position: absolute;
		top:0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/* OFFCANVAS NAV */
.offcanvas-toggle.is-open .icon-bar:nth-child(3) {  /* Noli: X not ligning up with larger span size for hamburger */
    -webkit-transform: rotate(-45deg) translate(5px,-5px);
    transform: rotate(-45deg) translate(5px,-5px);
}

#js-bootstrap-offcanvas {
	background: $brand-secondary;
}
.navbar-toggle {
	.icon-bar {
		display: block;
		width: 25px;
		height: 3px;
		border-radius: 1px;
	}
	.icon-bar+.icon-bar {
		margin-top: 4px;
	}
}
header .nav-primary {
	.in .nav {
		display: table;

		& > li a {
			font-weight: 700;
		}
		li {
			padding: 0;
			a {
				padding: 0.6em 0 0.5em;
				font-size: 1em;
				border-bottom: 1px solid $almost-gray;
			}
        	&.mobile {
				display:block;
			}			
		}
		li .sub-menu {
			display: block;
			position: relative;
			left: auto;
			top: auto;
			padding-left: 0.5em;
			padding-top:0;
			padding-bottom: 0;

			a { font-weight: 400; }

			li .sub-menu {
				display: block;
				position: relative;
				left: auto;
				top: auto;
			}
		}
		li:hover ul {
			border-left: 0;
			border-right: 0;
			border-bottom: 0;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			box-shadow: none;
			background: inherit;
			a {
				padding: .6em 0 .5em;
			}
		}
	}
}

/* media queries */
@media (max-width: 992px) {}

@media (max-width: 768px) {}

.row.no-gutters {
	margin-right: 0 !important;
	margin-left: 0 !important;
	& > [class^="col-"],
	& > [class*=" col-"] {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
}


/* from /wp-content/themes/tamarin/bower_components/slick-carousel/slick/slick-theme.css (not loading this url) */
@charset 'UTF-8';
/* Slider */
.slick-loading .slick-list {
    background: #fff url('./ajax-loader.gif') center center no-repeat;
}
/* Icons */
@font-face {
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;
    src: url('/wp-content/themes/tamarin/bower_components/slick-carousel/slick/fonts/slick.eot');
    src: url('/wp-content/themes/tamarin/bower_components/slick-carousel/slick/fonts/slick.eot?#iefix') format('embedded-opentype'), url('/wp-content/themes/tamarin/bower_components/slick-carousel/slick/fonts/slick.woff') format('woff'), url('/wp-content/themes/tamarin/bower_components/slick-carousel/slick/fonts/slick.ttf') format('truetype'), url('/wp-content/themes/tamarin/bower_components/slick-carousel/slick/fonts/slick.svg#slick') format('svg');
}
/* Arrows */
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 38%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: .25;
}
.slick-prev:before,
.slick-next:before {
    font-family: 'slick';
    font-size: 30px;
    line-height: 1;
    opacity: .75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-prev {
    left: -25px;
}
[dir='rtl'] .slick-prev {
    right: -25px;
    left: auto;
}
.slick-prev:before {
    /*content: 'â†';*/
	content: "←";
}
[dir='rtl'] .slick-prev:before {
    /*content: 'â†’';*/
	content: "←";
}
.slick-next {
    right: -25px;
}
[dir='rtl'] .slick-next {
    right: auto;
    left: -25px;
}
.slick-next:before {
    /*content: 'â†’';*/
	content: "→";
}
[dir='rtl'] .slick-next:before {
    /*content: 'â†';*/
	content: "→";
}

/* Dots */
.slick-dotted.slick-slider {
    margin-bottom: 30px;
}
.slick-dots {
    position: absolute;
    bottom: 50px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
.slick-dots li {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 35px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}
.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 35px;
    height: 35px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1;
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 27px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
	content: "";
    text-align: center;
    opacity: .4;
    color: black;
	border: 2px solid white;	
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
    opacity: 1;
    color: white;
	border: 3px solid white;
}
